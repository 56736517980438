<template>
    <modal ref="modalUbicacionRegistro" titulo="Tu ubicación" tamano="modal" cancelar="Cerrar" adicional="Aceptar" @adicional="seleccionar">
        <div class="row mx-0 j-center mb-4 mt-3">
            <div class="col-10">
                <p class="ml-3 f-13">Pais</p>
                <el-select v-model="pais" class="w-100" placeholder="Seleccionar" @change="seleccionarCiudades">
                    <el-option
                    v-for="item in paises"
                    :key="item.id"
                    :label="item.pais"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
        <div class="row mx-0 j-center mb-5 mt-3">
            <div class="col-10">
                <p class="ml-3 f-13">Ciudad</p>
                <el-select v-model="idCiudad" class="w-100" filterable placeholder="Seleccionar" @change="seleccionoCiudad">
                    <el-option
                    v-for="item in ciudades"
                    :key="item.id"
                    :label="item.ciudad"
                    :value="item.id"
                    />
                </el-select>
            </div>
        </div>
    </modal>
</template>

<script>
import Service from '~/services/auth';

export default {
    data(){
        return {
            paises: [],
            ciudadesPais: [],
            ciudades: [],
            pais: null,
            idCiudad: null,
            ciudad: null,
        }
    },
    methods: {
        async getPaises(){
            try {
                const { data } = await Service.getPaises();
                this.paises = data.paises;
                this.ciudadesPais = data.ciudades;
                if(this.paises.length === 1){
                    this.pais = this.paises[0].id
                    this.seleccionarCiudades()
                }
                
            } catch(e){
                this.errorCatch(e)
            }
        },

        async seleccionarCiudades(){
            this.idCiudad = null;
            let data = this.ciudadesPais.find((item) => item.id_pais === this.pais);
            this.ciudades = data.ciudades;
        },

        async seleccionoCiudad(){
            let data = this.ciudades.find((item) => item.id === this.idCiudad);
            this.ciudad = data.ciudad;
        },

        seleccionar(){
            if(!this.idCiudad)return;

            let pais = this.paises.find((item) => item.id === this.pais);
            let data = {};
            data.mostrar = this.ciudad+", "+pais.pais;
            data.idCiudad = this.idCiudad;

            this.$emit('changeUbicacion', data);
            this.$refs.modalUbicacionRegistro.toggle();
        },

        toggle(){
            this.getPaises();
            this.$refs.modalUbicacionRegistro.toggle();
        }
    }
}
</script>
<style lang="scss" scoped>
.card-country{
    width: 110px;
    .border-card{
        border: 2px solid transparent;
        &-active{
            display: block;
            border: 2px solid var(--color-general);
        }
    }
}
</style>